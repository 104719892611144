/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
// // { gmKey: 'g-103--cargo-bay', transform: [1, 0, 0, 1, 1200, 0] },
// 🚧 migration only
// 🚧 do not use jsx comments here
// { gmKey: 'g-103--cargo-bay', transform: [1, 0, 0, 1, 1200, 0] },
// { type: 'component', class: 'World', filepath: 'world-demo-2' },
// { type: 'component', class: 'SvgNavGraph', filepath: 'example/SvgNavGraph#102', props: { layoutKey: 'g-102--research-deck' } },
// PROFILE: `choice "Hello ${ansi.Reverse}there${ansi.Reset} \n\r ok [foo](bar)"`,
// { type: 'terminal', filepath: 'demo-2@test', env: { WORLD_KEY: 'world-demo-2', PROFILE: profileLookup.game_1() } },
// { type: 'terminal', filepath: 'demo-3@test', env: { PROFILE: profileLookup.util_0() } },
// { type: 'component', class: 'SvgStringPull', filepath: 'example/SvgStringPull', props: { layoutKey: 'g-301--bridge' } },
// { type: 'component', class: '__TODO__', filepath: 'example/Css3dForeignObject#301' },
// { type: 'component', class: '__TODO__', filepath: 'example/Pyramid3dDemo' },
// { type: 'component', class: '__TODO__', filepath: 'example/TriangleDev#301' },
// { type: 'component', class: '__TODO__', filepath: 'example/SvgDoorsDemo#101' },
// { type: 'component', class: 'SvgNavGraph', filepath: 'example/SvgNavGraph#101', props: { layoutKey: 'g-101--multipurpose', } },
// { type: 'component', class: 'SvgNavGraph', filepath: 'example/SvgNavGraph#301', props: { layoutKey: 'g-301--bridge', } },
// { type: 'component', class: '__TODO__', filepath: 'example/SvgPanZoomDemo' },
// { type: 'component', class: '__TODO__', filepath: 'example/SvgVisibilityDemo#301' },
/*```jsx
export default function FooBar() {
const mine = {
foo: 'bar',
baz: 'qux',
};
return (
<MyComponent foo={42} bar="hi">
<div />
</MyComponent>
);
}

const rootCss = css`
display: none;
transform: translate(2px, 2px);
transition: 2s ease-in;
// etc.

@media (max-width: 600px) {
display: none;
}
`;
```*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Tabs from "components/page/Tabs";
import {profileLookup} from "projects/sh/scripts";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "@test"), "\n", React.createElement(Tabs, {
    id: "test-tabs",
    initEnabled: false,
    height: 600,
    persistLayout: true,
    tabs: [[{
      type: 'component',
      class: 'WorldPixi',
      filepath: 'world-pixi-demo-1',
      props: {
        worldKey: 'world-pixi-demo-1',
        gms: [{
          gmKey: 'g-301--bridge'
        }, {
          // // { gmKey: 'g-103--cargo-bay', transform: [1, 0, 0, 1, 1200, 0] },
          gmKey: 'g-101--multipurpose',
          transform: [1, 0, 0, 1, 0, 600]
        }, {
          gmKey: 'g-302--xboat-repair-bay',
          transform: [1, 0, 0, -1, -1200 * 2, 600 + 1200]
        }, {
          gmKey: 'g-303--passenger-deck',
          transform: [1, 0, 0, -1, -1200, 1200 + 600]
        }, {
          gmKey: 'g-302--xboat-repair-bay',
          transform: [-1, 0, 0, 1, 1200 + 1200, 600]
        }, {
          gmKey: 'g-301--bridge',
          transform: [1, 0, 0, -1, 0, 600 + 1200 + 600]
        }, {
          gmKey: 'g-102--research-deck',
          transform: [1, 0, 0, 1, -1200, 0]
        }]
      }
    }, {
      type: 'component',
      class: 'GeomorphEdit',
      filepath: 'geomorph/GeomorphEdit',
      props: {}
    }], [{
      type: 'terminal',
      filepath: 'demo-pixi-1@test',
      env: {
        WORLD_KEY: 'world-pixi-demo-1',
        WORLD_KEY_ALT: 'world-pixi-demo-1',
        // 🚧 migration only
        PROFILE: profileLookup.game_3()
      }
    }]]
  }), "\n", React.createElement(Tabs, {
    id: "test-tabs-old",
    initEnabled: false,
    height: 600,
    persistLayout: true,
    tabs: [[{
      // 🚧 do not use jsx comments here
      type: 'component',
      class: 'World',
      filepath: 'world-demo-1',
      props: {
        worldKey: 'world-demo-1',
        gms: [{
          gmKey: 'g-301--bridge'
        }, {
          // { gmKey: 'g-103--cargo-bay', transform: [1, 0, 0, 1, 1200, 0] },
          gmKey: 'g-101--multipurpose',
          transform: [1, 0, 0, 1, 0, 600]
        }, {
          gmKey: 'g-302--xboat-repair-bay',
          transform: [1, 0, 0, -1, -1200, 600 + 600 + 1200]
        }, {
          gmKey: 'g-303--passenger-deck',
          transform: [1, 0, 0, -1, -1200, 1200 + 600]
        }, {
          gmKey: 'g-302--xboat-repair-bay',
          transform: [-1, 0, 0, 1, 1200 + 1200, 600]
        }, {
          gmKey: 'g-301--bridge',
          transform: [1, 0, 0, -1, 0, 600 + 1200 + 600]
        }, {
          gmKey: 'g-102--research-deck',
          transform: [1, 0, 0, 1, -1200, 0]
        }]
      }
    }, {
      type: 'component',
      class: 'GeomorphEdit',
      filepath: 'geomorph/GeomorphEdit',
      props: {}
    }], [{
      type: 'terminal',
      filepath: 'demo-1@test',
      env: {
        WORLD_KEY: 'world-demo-1',
        // PROFILE: `choice "Hello ${ansi.Reverse}there${ansi.Reset} \n\r ok [foo](bar)"`,
        PROFILE: profileLookup.game_2()
      }
    }]]
  }), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
