/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import "katex/dist/katex.min.css";
import Icon, {RoadWorksIcon} from 'components/page/Icon';
import SideNote from "components/page/SideNote";
import CollideNpcs from "../components/snippet/collide-npcs.mdx";
import CollideNpcRect from "../components/snippet/collide-npc-rect.mdx";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Sketches ", React.createElement(RoadWorksIcon)), "\n", React.createElement(_components.p, null, "This page contains snippets that do not yet have a home."), "\n", React.createElement(CollideNpcs), "\n", React.createElement(CollideNpcRect));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
