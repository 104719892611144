/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*> A ghost attracts attention (curiosity).

> NPCs do things in multiple ways (choice).

> NPCs remember and forget (time sensitive).

<Aside title="clarify-sophisticated-behaviour">
Technically these have all been done many times before.
For example, an NPC with two attacks already makes choices.
But these behaviours are rarely freely composable.
> e.g. a polite NPC might let the Player through a door first (choice),
and notice something while waiting (curiosity),
which they later recollect (time sensitive).

We are interested in combining behaviours,
whilst maintaining the Player's awareness.
</Aside>

But how are we going to create more sophisticated behaviour?*/
/*We have a few core ideas.*/
/*some kinds of "sophisticated" character behaviour may be incompatible with a video game e.g. players replaying scenarios in "Last of Us" may want to repeatability*/
/*- We consider character behaviour relevant to a vast number of video games.*/
/*Finally, we must ensure our desire for generality does not produce a dry abstract approach.*/
/*Entertainment will arise by interacting with our little fabricated worlds.*/
/*We'll program Non-player Characters ([NPCs](https://en.wikipedia.org/wiki/Non-player_character)) and programming requires patience.*/
/*Check out the profile of the terminal below.*/
/*```jsx
// internals and return value simplified
export default function NPC({ api, npcKey }) {
const state = useStateRef(() => {...}); // `api.npcs.npc[npcKey]`

return (
<div
ref={state.npcRef}
className={`npc ${state.anim.spriteSheet} ...`}
>
<div className={`body webp ${state.key}`} />
<div className="interact-circle" />
<div className="bounds-circle" />
<div
className="head-circle"
data-meta={JSON.stringify({ npc: true, ui: true, npcKey: state.key })}
/>
</div>
);
}
```

Each of the above divs has an associated purpose:
- `div.npc`: translation.
- `div.body`: rotation, opacity, spritesheet.
- `div.interact-circle`: interact radius.
- `div.bounds-circle`: collision radius.
- `div.head-circle`: clickable.*/
/*Each World instantiates subcomponents including [`<NPCs>`](https://github.com/rob-myers/the-last-redoubt/blob/staging/src/projects/world/NPCs.jsx).
The latter has state `api.npcs` and instantiates each NPC.*/
/*🚧 shell command (or _command_)

🚧 game command: command using some World API

🚧 npc behaviour (or _behaviour_): game command using some World npcs API*/
/*NPC behaviour may be _simulated_ i.e. task-driven imitations of human behaviour. NPC behaviour may be _fabricated_ i.e. specifically constructed in response to the Player's actions.*/
/*For example, if the Player enters a particular room we could spawn enemies (fabrication) which are instructed to chase after the Player (simulation).*/
/*By this we mean an interactive [Command Line Interface](https://en.wikipedia.org/wiki/Command-line_interface) running in the browser.
Graphically it amounts to [Xterm.js](https://github.com/xtermjs/xterm.js#readme).<SideNote>Keypresses are handled by our own analog of [Readline](https://en.wikipedia.org/wiki/GNU_Readline).</SideNote>
Programmatically it interprets POSIX shell syntax as JavaScript and executes it.<SideNote>We parse shell syntax using the wonderful [mvdan-sh](https://www.npmjs.com/package/mvdan-sh).</SideNote>
Supported syntax includes shell pipelines, shell functions and background processes.*/
/*<Aside title="interpret-js">

But how is shell syntax interpreted as JavaScript?

> Consider our shell function `call`.<br/>
> Given a textual description of a JavaScript function and its arguments, it creates the JavaScript function and its arguments, and finally invokes this function with these arguments.

As another example, the shell builtin `run` creates and iterates a JavaScript [AsyncGenerator function](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function*).
The shell functions `call`, `map`, `range` and `split` (above) are all defined in terms of `run`.

</Aside>*/
/*There are two basic ways to create browser-based video games:
[HTMLCanvasElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement) and
[CSS](https://developer.mozilla.org/en-US/docs/Web/CSS).
The `<canvas>` amounts to an image we can manipulate via JavaScript.
We can fill shapes, put pixels, or even [render 3D worlds](https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API) at 60 frames per second.
When someone says _HTML5 game_, the underlying tech is almost always the `<canvas>` element.
On the other hand, non-gaming websites rarely use it.

The cornerstones of the World Wide Web are HTML, CSS and JavaScript.
A typical website amounts to a deep [DOM-tree](https://en.wikipedia.org/wiki/Document_Object_Model) initially induced by HTML, brought to life _visually_ via CSS <SideNote>e.g. `<link>` and `<style>` nodes</SideNote> and _interactively_ via JavaScript<SideNote>Technically, JS permits arbitrary DOM mutation.</SideNote>.
Concerning CSS, web browsers interpret it very efficiently.
Its declarative approach avoids re-inventing the wheel via heavy-duty JavaScript.<SideNote>Although HTMLCanvas is performant via [WebGL](https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API), the latter is a low-level API.</SideNote>
Finally, CSS permits _rich interactive debugging_.
That is, one may directly mutate the DOM-tree in a browser devtool,
whereas debugging `<canvas>` amounts to setting breakpoints inside JavaScript code.*/
/*We want to program NPCs, producing a catalogue of interesting behaviours.
We are particularly interested in studying how they can be composed in response to the Player's actions.
However, NPC behaviours need to be studied at the right level of abstraction.
We are not trying to create _middleware_.
We do not want to create a generic Game AI system, upon which "real games" can be scaffolded.
_Why?_
Because NPC behaviour is highly contextual.
It is usually brought to life via assets representing a specific situation: suitable background scenery and animations of particular denizens.

We definitely need this _spice_ i.e. assets and animations which bring our NPCs to life.
> Thankfully we can use [Starship Geomorphs](http://travellerrpgblog.blogspot.com/2020/07/starship-geomorphs-20.html "@new-tab") by Robert Pearce,
> a large number of aerial-view assets representing Starships, Space-stations, Space-colonies etc.
*/
/*### Changelog

| date | change |
| --- | ----------- |
| 2022-??-?? | created [Home page](/) |
|  | created [About](/about) |
|  | created preview [Setup](/intro/setup) |
|  | created preview [JavaScript CLI](/intro/js-cli) |
|  | created preview [Game AI CLI](/intro/ai-cli) |*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Carousel from "components/page/Carousel";
import Icon, {RoadWorksIcon} from "components/page/Icon";
import InlineCode from "components/page/InlineCode";
import SideNote from "components/page/SideNote";
import Tabs from "components/page/Tabs";
import Aside from "components/page/Aside";
import {ansi} from "projects/service/const";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    strong: "strong",
    a: "a",
    ul: "ul",
    li: "li",
    ol: "ol",
    code: "code",
    em: "em",
    pre: "pre",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "home"), "\n", React.createElement(_components.h3, null, "Introduction"), "\n", React.createElement(_components.p, null, "We want to make video games more meaningful. That is the objective of this website. Video games suffer from an underlying problem: ", React.createElement(_components.strong, null, "simplistic character behaviour"), " (NPC as a ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/NPC_(meme)"
  }, "meme"), "). Exceptional games have sidestepped this, some recent examples being:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/What_Remains_of_Edith_Finch"
  }, "What Remains of Edith Finch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Observation_(video_game)"
  }, "Observation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/The_Last_of_Us_Part_II"
  }, "The Last of Us Part II"), " ", React.createElement(SideNote, null, "Admittedly very violent, but not in a meaningless way.")), "\n"), "\n", React.createElement(_components.p, null, "The above avoid simplistic behaviour by employing a ", React.createElement(_components.strong, null, "tight narrative"), ", combined with exploration, mini-games and constrained scenarios.\nNevertheless, superficial character behaviour is the norm for video games.", React.createElement(SideNote, {
    width: 450
  }, "Simplistic behaviour can be \"part of the game\" e.g. Arcade Games, Puzzles Games."), React.createElement(SideNote, null, "On the other hand, even masterpieces like ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Quake_1"
  }, "Quake 1"), ", ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Fallout_2"
  }, "Fallout 2"), ", ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/GTA_2"
  }, "GTA 2"), ", ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Thief_2"
  }, "Thief 2"), " suffer from simplistic behaviour")), "\n", React.createElement(Carousel, {
    id: "first-peek-video",
    height: 650,
    mobileHeight: 400,
    fullHeight: 900,
    items: [{
      video: 'first-peek',
      label: 'First peek'
    }]
  }), "\n", React.createElement(_components.p, null, "We believe interesting character behaviour is the lynchpin of meaningful gameplay.\nWithout it, virtual worlds become stale.\nRather than avoiding the problem by tightening the narrative,\nwe'd prefer to develop more sophisticated behaviour.\nBut how?"), "\n", React.createElement(_components.p, null, "We have a few core ideas."), "\n", "\n", "\n", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Create and combine character behaviours without trying to create a video game."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Develop differently:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Declarative rendering via HTML, CSS and ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Web_Animations_API/Web_Animations_API_Concepts"
  }, "WAAPI"), "."), "\n", React.createElement(_components.li, null, "In-browser scripting via ", React.createElement(_components.a, {
    href: "https://pubs.opengroup.org/onlinepubs/9699919799/utilities/V3_chap02.html#tag_18"
  }, "POSIX"), " syntax interpreted as JavaScript."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use openly available assets:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Robert Pearce's ", React.createElement(_components.a, {
    href: "http://travellerrpgblog.blogspot.com/2020/07/starship-geomorphs-20.html"
  }, "Starship Geomorphs"), " and Eric Smith's ", React.createElement(_components.a, {
    href: "http://ericbsmith.no-ip.org/geomorphs/"
  }, "PNG format"), "."), "\n", React.createElement(_components.li, null, "Robert Brook's ", React.createElement(_components.a, {
    href: "https://www.gamedeveloperstudio.com/graphics/viewgraphic.php?page-name=Top-down-men-game-asset-character-pack&item=1r6d547z6f232v871n"
  }, "Top down men game asset character pack"), ". ", React.createElement(SideNote, null, "Not free but ", React.createElement(_components.code, null, "$14.95"), " (at time of writing) including ", React.createElement(_components.a, {
    href: "https://brashmonkey.com/spriter-pro/"
  }, "Spriter"), " source files.")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Traveller_(role-playing_game)"
  }, "Traveller universe"), " as background context.", React.createElement(SideNote, null, "The concept of ", React.createElement(_components.a, {
    href: "https://wiki.travellerrpg.com/Starship_Geomorphs"
  }, React.createElement(_components.em, null, "Starship Geomorph")), " comes from Traveller.")), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Then we intend to approach the problem directly,\nby creating and combining behaviours for their own sake.\nHowever, this is a difficult thing to do: we risk creating something neither of ", React.createElement(_components.strong, null, "general interest"), " nor with much ", React.createElement(_components.strong, null, "entertainment value"), "."), "\n", React.createElement(Carousel, {
    baseSrc: "/assets/pics/first-peek/",
    height: 550,
    mobileHeight: 450,
    fullHeight: 800,
    items: [{
      src: 'npcs-1.png',
      webp: true,
      label: 'A World (left) and a Terminal (right)'
    }]
  }), "\n", React.createElement(_components.p, null, "We tackle ", React.createElement(_components.em, null, "general interest"), " in a few ways."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our implementation uses ", React.createElement(_components.em, null, "standard"), " web development techniques, e.g.\n", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/CSS"
  }, "CSS"), " and the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Web_Animations_API"
  }, "Web Animations API"), " for rendering,\nrather than a non-declarative approach invoking ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame"
  }, React.createElement(_components.code, null, "requestAnimationFrame")), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our approach to in-browser scripting is of independent interest.\nWe provide an interactive shell interpreting POSIX syntax as JavaScript.", React.createElement(SideNote, null, "Interactively parsed using the wonderful ", React.createElement(_components.a, {
    href: "https://github.com/mvdan/sh/tree/master/_js"
  }, "mvdan-sh"), ",")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Our ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Command-line_interface"
  }, "CLI"), " is extended with generic ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Non-player_character"
  }, "NPC"), " shell functions", React.createElement(SideNote, null, "CLI stands for ", React.createElement(_components.em, null, "Command Line Interface"), ", and NPC stands for ", React.createElement(_components.em, null, "Non-Player Character"), "."), React.createElement(SideNote, null, "These shell functions give our website its name."), " e.g. ", React.createElement(_components.code, null, "click"), ", ", React.createElement(_components.code, null, "spawn"), ", ", React.createElement(_components.code, null, "look"), ", ", React.createElement(_components.code, null, "nav"), ", ", React.createElement(_components.code, null, "walk"), ".\nThey can be naturally piped:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "# walk npc \"rob\" to clicked navigable points\nclick | nav rob | walk rob\n")), "\n"), "\n"), "\n", "\n", React.createElement(_components.p, null, "But what about ", React.createElement(_components.em, null, "entertainment value"), "?\nIf we're going to make more meaningful video games,\nthey'd better be more entertaining too.\nAdditional depth must be seamlessly integrated into the experience.\nFor example:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sometimes deterministic behaviour is desirable to avoid frustrating the Player."), "\n", React.createElement(_components.li, null, "Complex simulations have little value if they are never witnessed or understood by the Player."), "\n"), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.em, null, "Player-centric approach"), " will avoid these pitfalls.\nThere is another important related problem:\nalthough we don't want to create a video game,\n", React.createElement(_components.strong, null, "we also don't want an abstract collection of behaviours"), ".\nThus we provide context on two levels: a setting (Traveller universe) and a narrative container (joining the behaviours together).\nThe latter is called \"The Experiment\"."), "\n", "\n", "\n", "\n", React.createElement(Carousel, {
    baseSrc: "/assets/pics/first-peek/",
    height: 600,
    mobileHeight: 450,
    fullHeight: 800,
    items: [{
      src: 'npcs-2.png',
      webp: true,
      label: 'Each World provides a birdsye view.'
    }, {
      src: 'npcs-3.png',
      webp: true,
      label: 'Each World is driven by one or more Terminals.'
    }]
  }), "\n", React.createElement(_components.h3, null, "Worlds, Players and Terminals"), "\n", React.createElement(_components.p, null, "Behaviour arises relative to a ", React.createElement(_components.em, null, "World"), ".\nDefining a World amounts to choosing ", React.createElement(_components.a, {
    href: "http://travellerrpgblog.blogspot.com/2020/07/starship-geomorphs-20.html"
  }, "Starship Geomorphs"), " and connecting them together.\nAt present, we only support 5 geomorphs (Robert Pearce provided hundreds) constructed from about 80 ", React.createElement(_components.a, {
    href: "http://travellerrpgblog.blogspot.com/2020/08/starship-symbols-book.html"
  }, "Starship Symbols"), React.createElement(SideNote, null, "Available in PNG format on ", React.createElement(_components.a, {
    href: "http://ericbsmith.no-ip.org/geomorphs/"
  }, "Eric Smith's site"), "."), " (Robert Pearce provided thousands)."), "\n", React.createElement(Carousel, {
    baseSrc: "/assets/geomorph/",
    height: 450,
    mobileHeight: 350,
    fullHeight: 800,
    items: [{
      src: 'g-101--multipurpose.map.png',
      webp: true,
      label: 'Geomorph 101 (Multipurpose)'
    }, {
      src: 'g-102--research-deck.map.png',
      webp: true,
      label: 'Geomorph 102 (Research Deck)'
    }, {
      src: 'g-301--bridge.map.png',
      webp: true,
      label: 'Geomorph 301 (Bridge)'
    }, {
      src: 'g-302--xboat-repair-bay.map.png',
      webp: true,
      label: 'Geomorph 302 (Xboat Repair Bay)'
    }, {
      src: 'g-303--passenger-deck.map.png',
      webp: true,
      label: 'Geomorph 303 (Passenger Deck)'
    }]
  }), "\n", React.createElement(_components.p, null, "The thin white rectangles are ", React.createElement(_components.em, null, "doors"), ". The larger doors on the edges are called ", React.createElement(_components.em, null, "hull doors"), ".\nThe geomorphs should be arranged so each hull door either (a) touches nothing else, (b) coincides with exactly one other hull door.\nThen we \"glue\" geomorphs together at their hull doors -- exactly their original purpose.\nBelow we have combined ", React.createElement(_components.code, null, "101"), ", ", React.createElement(_components.code, null, "301"), ", and also ", React.createElement(_components.code, null, "101"), ", ", React.createElement(_components.code, null, "102"), ", ", React.createElement(_components.code, null, "303"), "."), "\n", React.createElement(Tabs, {
    id: "world-map-only",
    initEnabled: false,
    height: [400, 500],
    persistLayout: true,
    tabs: [[{
      type: 'component',
      class: 'World',
      filepath: 'map-only-1',
      props: {
        init: {
          y: 600,
          zoom: 0.3
        },
        worldKey: 'map-only-1',
        gms: [{
          gmKey: 'g-101--multipurpose',
          transform: [-1, 0, 0, 1, 1200, 0]
        }, {
          gmKey: 'g-301--bridge',
          transform: [0, 1, -1, 0, 1200 + 600, 0]
        }, {
          gmKey: 'g-301--bridge',
          transform: [0, -1, 1, 0, -600, 1200]
        }]
      }
    }, {
      type: 'component',
      class: 'World',
      filepath: 'map-only-2',
      props: {
        init: {
          x: 600,
          y: 600,
          zoom: 0.3
        },
        worldKey: 'map-only-2',
        gms: [{
          gmKey: 'g-101--multipurpose'
        }, {
          gmKey: 'g-102--research-deck',
          transform: [1, 0, 0, 1, 1200, 0]
        }, {
          gmKey: 'g-102--research-deck',
          transform: [-1, 0, 0, 1, 0, 0]
        }, {
          gmKey: 'g-303--passenger-deck',
          transform: [1, 0, 0, 1, 0, -600]
        }, {
          gmKey: 'g-303--passenger-deck',
          transform: [1, 0, 0, -1, 0, 600 + 1200]
        }]
      }
    }]]
  }), "\n", React.createElement(Aside, {
    title: "can-pan-zoom-tabs"
  }, React.createElement(_components.p, null, "In the maps above\nyou can pan (", React.createElement(_components.em, null, "drag"), "),\nyou can zoom (", React.createElement(_components.em, null, "pinch"), " or ", React.createElement(_components.em, null, "scroll"), "),\nand you can drag or maximise their tabs", React.createElement(SideNote, null, "Thanks to the wonderful tabs manager ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/flexlayout-react"
  }, "flexlayout-react"), "."), ".\nIn fact we have just seen two ", React.createElement(_components.em, null, "Worlds"), " i.e. ", React.createElement(_components.a, {
    href: "https://github.com/rob-myers/the-last-redoubt/blob/staging/src/projects/world/World.jsx",
    title: "@new-tab"
  }, "World.js"), " component instances.\nThe first map amounts to the following component instantiation:"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<World\n  worldKey=\"map-only-1\"\n  gms={[\n    { gmKey: 'g-101--multipurpose', transform: [-1, 0, 0, 1, 1200, 0] },\n    { gmKey: 'g-301--bridge', transform: [0, 1, -1, 0, 1200 + 600, 0] },\n    { gmKey: 'g-301--bridge', transform: [0, -1, 1, 0, -600, 1200], },\n  ]}\n/>\n")), React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "worldKey"), " identifies the World's API,\nwhereas ", React.createElement(_components.code, null, "gms"), " specifies the geomorphs and their layout.\nBut why do these worlds look like maps?"), React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Because there is no ", React.createElement(_components.em, null, "Player"), " and thus no ", React.createElement(_components.em, null, "Field Of View"), ".", React.createElement(SideNote, null, "We already saw the Player-sensitive FOV (Field Of View) in the ", React.createElement(_components.a, {
    href: "#first-peek-video"
  }, "video earlier"), ".")), "\n")), "\n", React.createElement(_components.p, null, "Our World needs a Player.\nThis brings us to a very important ingredient, the ", React.createElement(_components.code, null, "<Terminal>"), ".\nDesigning user interfaces is ", React.createElement(_components.em, null, "hard"), ".\nInsofar as textual user interfaces are concerned, we've gone the canonical route i.e. ", React.createElement(_components.a, {
    href: "https://pubs.opengroup.org/onlinepubs/9699919799/utilities/V3_chap02.html"
  }, "POSIX"), ".\nSyntactically we provide an interactive shell.\nSemantically we manipulate Javascript values and run async generators."), "\n", React.createElement(_components.p, null, "Let's see it in action.\nThe Terminal's ", React.createElement(_components.em, null, "profile"), " explains itself step-by-step,\nprompting the user to \"tap to continue\".\nBy the way, each ", React.createElement(_components.code, null, "<Terminal>"), " has a side-menu ", React.createElement(_components.code, null, "<"), "\nparticularly helpful for mobile devices."), "\n", React.createElement(Tabs, {
    id: "tabs@world-1",
    initEnabled: false,
    height: [600, 600],
    persistLayout: true,
    tabs: [[{
      type: 'component',
      class: 'World',
      filepath: 'world-1',
      props: {
        worldKey: 'world-1',
        init: {
          zoom: 0.5,
          y: 600,
          ms: 0
        },
        gms: [{
          gmKey: 'g-101--multipurpose',
          transform: [-1, 0, 0, 1, 1200, 0]
        }, {
          gmKey: 'g-301--bridge',
          transform: [0, 1, -1, 0, 1200 + 600, 0]
        }, {
          gmKey: 'g-301--bridge',
          transform: [0, -1, 1, 0, -600, 1200]
        }]
      }
    }], [{
      type: 'terminal',
      filepath: 'tty@world-1',
      env: {
        WORLD_KEY: 'world-1',
        PROFILE: `source /etc/util-1
source /etc/game-1

awaitWorld

NOECHO=1 choice "We have ☝️
- defined shell functions
- connected to a world

tap to [ continue ](-)"

spawn rob '{x: -90, y: 1075}'
npc set-player rob
track rob &

NOECHO=1 choice "We have ☝️
- spawned an npc \\"rob\\"
- set them as the Player
- started a follow camera

tap to [ continue ](-)"

npc map hide
goLoop rob &
doLoop rob &
lookLoop rob &

NOECHO=1 echo "Finally we ☝️
- faded the map
- set the Player's controls:
  - tap navmesh (${ansi.Bold}move${ansi.BoldReset})
  - tap doors/icons (${ansi.Bold}do things${ansi.BoldReset})
  - tap elsewhere (${ansi.Bold}look${ansi.BoldReset})"
`
      }
    }]]
  }), "\n", React.createElement(Aside, {
    title: "do-all-the-things"
  }, React.createElement(_components.p, null, "Things to try with the World and Terminal above:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Open a door."), "\n", React.createElement(_components.li, null, "Lie in a bed."), "\n", React.createElement(_components.li, null, "Sit on the bog."), "\n", React.createElement(_components.li, null, "Reset everything (", React.createElement(Icon, {
    icon: "refresh",
    small: true,
    inline: true
  }), ")."), "\n", React.createElement(_components.li, null, "Pause everything (", React.createElement(Icon, {
    icon: "cross-circle",
    small: true,
    inline: true
  }), ")."), "\n", React.createElement(_components.li, null, "Follow the Player by running ", React.createElement(InlineCode, {
    copy: true
  }, "track rob"), "."), "\n", React.createElement(_components.li, null, "Stop following via Ctrl-C (", React.createElement(_components.em, null, "kill"), " in side menu)."), "\n", React.createElement(_components.li, null, "List background processes ", React.createElement(InlineCode, {
    copy: true
  }, "ps"), "."), "\n", React.createElement(_components.li, null, "Kill background processes ", React.createElement(InlineCode, {
    copy: true
  }, "kill 8 18 26"), ".", React.createElement(SideNote, null, "You can also pause/resume processes via ", React.createElement(_components.code, null, "--STOP"), " and ", React.createElement(_components.code, null, "--CONT"))), "\n", React.createElement(_components.li, null, "List shell functions ", React.createElement(InlineCode, {
    copy: true
  }, "declare -F"), "."), "\n", React.createElement(_components.li, null, "Inspect a shell script ", React.createElement(InlineCode, {
    copy: true
  }, "/etc/util-1"), "."), "\n"), React.createElement(Carousel, {
    id: "do-all-the-things-video",
    height: 550,
    mobileHeight: 400,
    fullHeight: 900,
    items: [{
      video: 'do-all-the-things',
      label: 'Doing all the things'
    }]
  })), "\n", React.createElement(_components.h3, null, "Fabricating a behaviour"), "\n", React.createElement(_components.p, null, "We're finally ready to create an NPC behaviour.\nWe need a ", React.createElement(_components.em, null, "World"), ", the ", React.createElement(_components.em, null, "Player"), " and an additional character i.e. ", React.createElement(_components.em, null, "NPC"), ".", React.createElement(SideNote, null, "Recall NPC stands for Non-Player Character.")), "\n", React.createElement(_components.p, null, "🚧"), "\n", "\n", React.createElement(Tabs, {
    id: "tabs@world-1+",
    initEnabled: false,
    height: [600, 600],
    persistLayout: true,
    tabs: [[{
      type: 'component',
      class: 'World',
      filepath: 'world-1+',
      props: {
        worldKey: 'world-1+',
        init: {
          zoom: 0.5,
          y: 600,
          ms: 0
        },
        gms: [{
          gmKey: 'g-101--multipurpose',
          transform: [-1, 0, 0, 1, 1200, 0]
        }, {
          gmKey: 'g-301--bridge',
          transform: [0, 1, -1, 0, 1200 + 600, 0]
        }, {
          gmKey: 'g-301--bridge',
          transform: [0, -1, 1, 0, -600, 1200]
        }]
      }
    }], [{
      type: 'terminal',
      filepath: 'tty@world-1+',
      env: {
        WORLD_KEY: 'world-1+',
        PROFILE: `source /etc/util-1
source /etc/game-1

awaitWorld

NOECHO=1 choice "${ansi.Reverse}${ansi.Bold}So far, this terminal's profile has defined shell functions and connected to world \\$WORLD_KEY.${ansi.Reset}
tap to [ continue ](-)"

spawn rob '{x: -90, y: 1075}'
npc set-player rob
view 1 '{x: -90, y: 1075}' 1.6

NOECHO=1 choice "${ansi.Reverse}${ansi.Bold}Next we've spawned an npc \\"rob\\", set them as the Player and pan-zoomed them into view.${ansi.Reset}
tap to [ continue ](-)"

npc map hide
goLoop rob &
doLoop rob &
lookLoop rob &

NOECHO=1 echo "${ansi.Reverse}${ansi.Bold}Finally we have faded the map and set up the Player's controls."
NOECHO=1 echo
NOECHO=1 echo "ℹ️  tap navmesh (to ${ansi.Bold}move${ansi.BoldReset}), tap nearby doors/icons (to ${ansi.Bold}do things${ansi.BoldReset}), and tap elsewhere (to ${ansi.Bold}look${ansi.BoldReset})."
`
      }
    }]]
  }), "\n", "\n", "\n", "\n", "\n", "\n", "\n", "\n", "\n", "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
