/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {RoadWorksIcon} from "components/page/Icon";
import InlineCode from "components/page/InlineCode";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h4: "h4",
    h5: "h5",
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Game AI CLI ", React.createElement(RoadWorksIcon)), "\n", React.createElement("aside", {
    title: "upcoming-article"
  }, React.createElement(_components.h4, null, "Upcoming topics"), React.createElement(_components.h5, null, "Core API"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "awaitWorld"), " awaits a ", React.createElement(_components.a, {
    href: "https://github.com/rob-myers/the-last-redoubt/blob/master/src/projects/world/World.jsx#:~:text=export%20default-,function%20World,-(props)",
    title: "@new-tab"
  }, "World component"), " with specified key."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "click"), " converts clicks and touches into world coordinates."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "look"), " makes an NPC look."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "nav"), " computes a navpath for an NPC."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "npc"), " configure NPCs and World."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "spawn"), " spawns an NPC."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "track"), " follows an NPC using panning and zooming."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "view"), " pan and/or zoom."), "\n", React.createElement(_components.li, null, React.createElement(InlineCode, null, "walk"), " makes an NPC walk."), "\n"), React.createElement(_components.h5, null, "Loops"), React.createElement(_components.p, null, React.createElement(InlineCode, null, "goLoop rob"), " makes Andros walk where you click."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "goLoop () {\n  click |\n    filter 'x => x.meta.nav && (!x.meta.ui || x.meta.go)' |\n    nav $1 |\n    walk $1\n}\n")), React.createElement(_components.p, null, React.createElement(InlineCode, null, "lookLoop rob"), " makes Andros look where you click."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "lookLoop () {\n  click |\n    filter 'x => !x.meta.nav && !x.meta.do' |\n    look $1\n}\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
