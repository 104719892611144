/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "About"), "\n", React.createElement(_components.h3, null, "Links"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "mailto:me.robmyers@gmail.com",
    title: "@new-tab"
  }, "me.robmyers@gmail.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.linkedin.com/in/robert-myers-4822ab18a/"
  }, "LinkedIn")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rob-myers/the-last-redoubt/"
  }, "Code on GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://codesandbox.io/dashboard/all/The%20Last%20Redoubt?workspace=549801c1-91a6-4104-84da-16da83c0a5c8"
  }, "CodeSandbox Collection")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://travellerrpgblog.blogspot.com/2020/07/starship-geomorphs-20.html"
  }, "Starship Geomorphs"), " also ", React.createElement(_components.a, {
    href: "http://ericbsmith.no-ip.org/geomorphs/"
  }, "as PNGs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mvdan/sh"
  }, "Mvdan/sh Parser"), " for interactively parsing POSIX shell syntax"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://boxy-svg.com/"
  }, "Boxy SVG"), " for editing SVGs"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://brashmonkey.com/"
  }, "Spriter Pro"), " for animating aerial-view characters"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/flexlayout-react"
  }, "flexlayout-react"), " for tabs layouts"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dblp.org/pid/81/8748.html"
  }, "Computer Science papers")), "\n"), "\n", React.createElement(_components.h3, null, "Copyrights"), "\n", React.createElement(_components.p, null, "Our game is built upon certain assets called ", React.createElement(_components.a, {
    href: "http://travellerrpgblog.blogspot.com/2020/07/starship-geomorphs-20.html"
  }, "Starship Geomorphs"), ".\nThey were created by Robert Pearce with the ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Traveller_(role-playing_game)"
  }, "Traveller Universe"), " in mind.\nThere are associated copyright notices for ", React.createElement(_components.em, null, "Traveller"), ", for the work of ", React.createElement(_components.em, null, "Robert Pearce"), ", and for the subsequent PNGs extracted by ", React.createElement(_components.em, null, "Eric Smith"), "."), "\n", React.createElement("aside", {
    title: "copyright-traveller"
  }, React.createElement(_components.p, null, "The Traveller game in all forms is owned by Far Future Enterprises. Copyright 1977-2020 Far Future Enterprises. Traveller is a registered trademark of Far Future Enterprises. Far Future permits web sites and fanzines for this game, provided it contains this notice, that Far Future is notified, and subject to a withdrawal of permission on a 90 days notice. The contents of this page are for personal, non-commercial use only. Any use of Far Future Enterprises's copyrighted material or trademarks anywhere in these documents should not be viewed as a challenge to those copyrights or trademark.")), "\n", React.createElement("aside", {
    title: "copyright-robert-pearce"
  }, React.createElement(_components.p, null, "Starship Geomorphs v2.0 is Copyright © 2020 by Pearce Design Studio, LLC and is licensed under a ", React.createElement(_components.a, {
    href: "https://creativecommons.org/licenses/by-nc/4.0/"
  }, "Creative Creative Commons Attribution Non-Commercial 4.0 International License"), ".")), "\n", React.createElement("aside", {
    title: "copyright-eric-smith"
  }, React.createElement(_components.p, null, "The Starship Geomorph/Symbols PNGs used as source material are Copyright © 2020 by ", React.createElement(_components.a, {
    href: "mailto:ericbsmith42@gmail.com"
  }, "Eric B. Smith"), " and are licensed under a ", React.createElement(_components.a, {
    href: "https://creativecommons.org/licenses/by-nc/4.0/"
  }, "Creative Creative Commons Attribution Non-Commercial 4.0 International License"), ".")), "\n", React.createElement("aside", {
    title: "copyright-flaticon"
  }, React.createElement("a", {
    href: "https://www.flaticon.com/free-icons/lying",
    title: "lying icons"
  }, "Lying icons created by Freepik - Flaticon"), React.createElement("br"), React.createElement("a", {
    href: "https://www.flaticon.com/free-icons/sit",
    title: "sit icons"
  }, "Sit icons created by Freepik - Flaticon"), React.createElement("br"), React.createElement("a", {
    href: "https://www.flaticon.com/free-icons/person",
    title: "person icons"
  }, "Person icons created by Uniconlabs - Flaticon")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
