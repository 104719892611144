/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import CaseBStationary from "./collide-npcs-stationary.mdx";
import CaseBMoving from "./collide-npcs-moving.mdx";
import SideNote from "components/page/SideNote";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    annotation: "annotation",
    blockquote: "blockquote",
    em: "em",
    ul: "ul",
    li: "li",
    msub: "msub",
    mo: "mo",
    mn: "mn"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Collision Prediction: NPC vs NPC"), "\n", React.createElement(_components.p, null, "Take two distinct NPCs ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), " and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), ".\nModel them as circles moving at constant speed towards particular points.\nWe permit an NPC to be stationary."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Do the NPC's intersect before they reach their target?")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Assume A is moving. ", React.createElement(SideNote, null, "If ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), " and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), " are both stationary, test the distance between the circle centers.")), "\n", React.createElement(_components.li, null, "Let ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "A"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "s_A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5806em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " (resp. ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "B"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "s_B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5806em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), ") be the speed of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), " (resp. of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), ")."), "\n", React.createElement(_components.li, null, "Let ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "A"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "r_A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5806em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "-0.0278em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " (resp. ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "B"))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "r_B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.5806em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "-0.0278em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), ") be the radius of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), " (resp. of ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), ")."), "\n", React.createElement(_components.li, null, "Let ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "s"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mrow, null, React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "m"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "i"), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "n")))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "dist_{\\rm min}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.8444em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "s"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3175em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathrm mtight"
  }, "min")))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))))))), " be the minimum distance allowed between NPC ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "A")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "A")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "A"))))), " and ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "B")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "B")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6833em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.05017em"
    }
  }, "B"))))), ",\ne.g. ", React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.msub, null, React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "A")), React.createElement(_components.mo, null, "+"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "B")), React.createElement(_components.mo, null, "−"), React.createElement(_components.mn, null, "0.1")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "r_A + r_B - 0.1")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7333em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "-0.0278em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "A")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.7333em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3283em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.55em",
      marginLeft: "-0.0278em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.05017em"
    }
  }, "B")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.6444em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "0.1"))))), "."), "\n"), "\n"), "\n", React.createElement(CaseBStationary), "\n", React.createElement(CaseBMoving));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
