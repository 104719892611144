exports.components = {
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-intro-ai-cli-mdx": () => import("./../../../src/pages/intro/ai-cli.mdx" /* webpackChunkName: "component---src-pages-intro-ai-cli-mdx" */),
  "component---src-pages-intro-index-mdx": () => import("./../../../src/pages/intro/index.mdx" /* webpackChunkName: "component---src-pages-intro-index-mdx" */),
  "component---src-pages-intro-js-cli-mdx": () => import("./../../../src/pages/intro/js-cli.mdx" /* webpackChunkName: "component---src-pages-intro-js-cli-mdx" */),
  "component---src-pages-intro-setup-mdx": () => import("./../../../src/pages/intro/setup.mdx" /* webpackChunkName: "component---src-pages-intro-setup-mdx" */),
  "component---src-pages-sketches-mdx": () => import("./../../../src/pages/sketches.mdx" /* webpackChunkName: "component---src-pages-sketches-mdx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */)
}

