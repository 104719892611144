/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
// import NpcSegSetup from "./collide-npc-seg-setup.mdx";
// import NpcSegSolve from "./collide-npc-seg-solve.mdx";
/*Rectangle needn't be axis-aligned.*/
/*Sketch algorithm*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SideNote from "components/page/SideNote";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Collision Prediction: NPC vs Angled Rect"), "\n", "\n", React.createElement(_components.p, null, "View an NPC as a circle moving at constant speed between two points.\nSuppose there is a static rectangle which needn't be axis-aligned (it may be rotated)."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Does the NPC intersect the rectangle before it reaches its target?"), "\n"), "\n", React.createElement(_components.p, null, "We actually answer an easier question.\nThat is, we view the circle as a square with axes aligned to the rectangle's."), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
